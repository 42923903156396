// import React from 'react'
// import { useState, useEffect, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { NavLink, useNavigate } from 'react-router-dom';
// // import Spinners from '../Loaders/Spinners';
// // import Loading from '../Loaders/Loading';

// import toast from 'react-hot-toast';
// import { persistor } from '../../../../../../store/store';
// import { logout } from '../../Slice/authSlice';


// export default function Header() {
//   const [isDropdown, setDropdown] = useState(false);
//   const handleDropdown = () => {
//     setDropdown(!isDropdown);
//   };

//   const dispatch =useDispatch()

//   const [isTransformed, setIsTransformed] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoading1, setIsLoading1] = useState(false);
// const navigate= useNavigate()
//   const user_email = useSelector((state)=>state.auth?.user?.email)
//   console.log("email",user_email)


//   const toggleModal = () => {
//     if (!isOpen) {
//       setIsLoading1(true);
//       setTimeout(() => {
//         setIsLoading1(false);
//         setIsOpen(!isOpen);
//       }, 1000);
//     } else {
//       setIsOpen(!isOpen);
//     }
//   };

//   useEffect(() => {
//     if (isOpen) {
//       setIsTransformed(true);
//     } else {

//       setIsTransformed(false);
//     }
//   }, [isOpen]);

//   const [ExpandSkill, setExpandSkill] = useState(false)
//   const [ExpandCourse, setExpandCourse] = useState(false)

//   const handleExpandSkill = () => {
//     setExpandSkill(!ExpandSkill);
//   };

//   const handleExpandCourse = () => {
//     setExpandCourse(!ExpandCourse);
//   };

//   const handleMouseEnterCourse = () => {
//     setExpandCourse(true);
//   };

//   const handleMouseLeaveCourse = () => {
//     setExpandCourse(false);
//   };

//   const handleMouseEnterSkill = () => {
//     setExpandSkill(true);
//   };

//   const handleMouseLeaveSkill = () => {
//     setExpandSkill(false);
//   };

//   const dropdownRef = useRef(null);
//   const buttonRef = useRef(null);

//   const handleClickOutside = (event) => {
//     if (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target)) {
//       setDropdown(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [isDropdown]);







// const Logout = (state) => {
//   state.user = null;
//   state.accessToken = null;
//   persistor.purge(); 
//   dispatch(logout())
//   navigate("/")
//   toast.success('Logged out successfully');
// };

//   const handleUserClick = () => {
//     setIsOpen(!isOpen);
//   };



//   const isAuthenticated = user_email !== undefined|| null  ;

// console.log("isAuthenticated",isAuthenticated)

//   return (
//     <div>
//       <nav className='flex items-center py-4 px-5 md:px-10  '>
//         <div className='w-[85%] lg:w-[15%]'>
//           <div className='w-[80%]'>
//             <a href='/'> <img src='/assets/Logo/d5artWhite.png' className='w-28'></img> </a>
//           </div>
//         </div>
//         <div className='w-[15%] lg:w-[85%] hidden lg:block'>
//           <div className='flex items-center' >
//             <div className='header_content w-[80%] relative text-gray-300 font-bold flex justify-center lg:gap-14'>
//               <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
//                 href='/'
//               >Home</a>
//               <div className='relative' onMouseLeave={() => { handleMouseLeaveCourse() }}>
//                 <a href='/courses'>
//                   <button

//                     className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
//                     onClick={() => { handleExpandCourse(); }}
//                     onMouseEnter={handleMouseEnterCourse}
//                   >
//                     Courses
//                     <span className='ml-2'>&#9660;</span> {/* Unicode down arrow */}
//                   </button>
//                 </a>
//                 <div
//                   className={`absolute z-40 -left-[45%] mt-2 rounded-md bg-black/75 backdrop-blur-sm w-[10rem] transition-all duration-700 overflow-hidden
//                     ${ExpandCourse ? 'max-h-[300px] border border-white/30' : 'max-h-0'}`}
//                 >
//                   <ul className="p-5 text-sm text-center flex flex-col gap-2 divide-transparent">
//                     <a href='/Courses' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Explore Courses</a>
//                     <a href='/seeker/dashboard' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Learning Dashboard</a>
//                     <a href='/seeker/dashboard?tab=certificate' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Certificate</a>
//                     <a href='/seeker/dashboard?tab=wallet' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Wallet</a>
//                     <a href='/seeker/dashboard?tab=result' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Assessment</a>
//                   </ul>
//                 </div>
//               </div>

//               <div className='relative' onMouseLeave={() => { handleMouseLeaveSkill() }}>
//                 <a href='/skill-market'>
//                   <button

//                     className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'
//                     onClick={handleExpandSkill}
//                     onMouseEnter={handleMouseEnterSkill}
//                   >
//                     Skill Exchange
//                     <span className='ml-2'>&#9660;</span>
//                   </button>
//                 </a>
//                 <div
//                   className={`absolute z-40 -left-4 mt-2 rounded-md bg-black/75 backdrop-blur-sm w-[10rem] transition-all duration-700 overflow-hidden 
//                     ${ExpandSkill ? 'max-h-[300px] border border-white/30' : 'max-h-0'}`}
//                 >
//                   <ul className="p-5 text-sm text-center flex flex-col gap-2 divide-transparent">
//                     <a href='/seeker-login' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Dashboard</a>
//                     <a href='/seeker-login' className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]'>Wallet</a>
//                   </ul>
//                 </div>
//               </div>
//               <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/jordan-event' >Events</a>
//               {/* <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/faq'>FAQ</a> */}
//               <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/event/start-assessment'>Know Yourself</a>
//               <a className='duration-500 hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r from-[#0EA4A9] to-[#FFBA00]' href='/dna-home'>DNA</a>

//             </div>
//             <div className='w-[20%] flex justify-center items-center'>
//               {isAuthenticated ? (
//                 <div className="gap-2 flex ">
//                   <div
//                     className="flex items-center gap-2 rounded-md bg- text-white  px-3 py-2 cursor-pointer"
//                     onClick={handleUserClick}
//                   >
//                     <button className="rounded-full w-10 h-10 border text-white flex items-center justify-center">
//                       {user_email.charAt(0).toUpperCase()}
//                     </button>
//                     <p className="user-username">
//                       {user_email.substr(0, 10) + "...."}
//                     </p>
//                     <i className="fa-solid fa-chevron-down pl-2 pt-1 text-xs"></i>
//                   </div>
//                   {isOpen && (
//                     <div className="absolute z-10 mt-10">
//                       <div className="rounded-lg w-[10rem]  text-white text-center mt-2 p-2 shadow-lg ring-1 ring-white ring-opacity-30 focus:outline-none bg-black">
//                         <button onClick={Logout}>
//                           <i className="fa-solid fa-right-from-bracket  fa-rotate-180 py-1"></i>
//                           &nbsp;&nbsp;Logout
//                         </button>
//                       </div>
//                     </div>
//                   )}
//                 </div>
//               ) : (
//                 <div className="flex gap-5">
//                   <NavLink to="/seeker-login">
//                     <div className="flex px-3 py-2 rounded-full items-center group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white">
//                       <button className="w-4 h-4 rounded-full  group-hover:translate-x-[107px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]">
//                         </button>{" "}
//                       <p className="mx-5 group-hover:text-lg group-hover:-translate-x-6 duration-500">
//                         Login
//                       </p>
//                       <button className=" h-7 w-7 rounded-full  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]">
//                       </button>
//                     </div>
//                   </NavLink>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//         <div className='w-[15%] table lg:hidden grid content-center'>
//           <div className='flex justify-end '>
//             <button onClick={handleDropdown} ref={buttonRef}>
//               <i className="fa-solid fa-bars text-white text-2xl "></i>
//             </button>
//             {isDropdown && (
//               <div className='dropdown-content text-black z-50 w-[13rem] absolute right-5 mt-2 top-20  animate__animated animate__fadeInUp' ref={dropdownRef}   aos='fade-up' data-aos-duration='1000'>
//                 <div className='flex flex-col gap-3 bg-black text-white rounded-lg p-5'>
//                   <a href='/' className='text-center'>Home</a>
//                   <a href='/courses' className='text-center'>Courses</a>
//                   <a href='/skill-market' className='text-center'>Skill Exchange</a>
//                   <a href='/jordan-event' className='text-center'>Events</a>
//                   <a href='/event/start-assessment' className='text-center'>Know Yourself</a>
//                   <a href='/dna-home' className='text-center'>DNA</a>

//                   {isAuthenticated ? (
//                 <div className="gap-2 flex ">

//                       <div className="rounded-lg w-[10rem]  text-white text-center mt-2 p-2 shadow-lg ring-1 ring-white ring-opacity-30 focus:outline-none bg-black">
//                         <button onClick={Logout}>
//                           <i className="fa-solid fa-right-from-bracket  fa-rotate-180 py-1"></i>
//                           &nbsp;&nbsp;Logout
//                         </button>
//                       </div>
//                     </div>

//               ) : (
//                   <div className='flex justify-center'>
//                     <NavLink to="/seeker-login">
//                       <button className='flex py-1 pt-2 px-3 bg-[#101010] rounded-full group border border-sky-700 hover:shadow-[0_0_0px_#fff,inset_0_0_0px_#fff,0_0_10px_#54f9e3,0_0_10px_#54f9e3] text-white' onClick={toggleModal}>
//                         <div className='p-1 rounded-full translate-y-1.5 group-hover:translate-x-[80px] hover:transform-transition duration-500 ease-in-out border border-sky-500 shadow-[0_0_1px_#fff,inset_0_0_1px_#fff,0_0_3px_#08f,0_0_3px_#08f,0_0_1px_#08f] mr-2'></div> 
//                         <p className='-translate-y-0.5 group-hover:text-lg group-hover:-translate-x-4 transform-transition duration-500 ease-in-out'>Login</p>
//                          <div className='ml-2 p-2.5 rounded-full translate-y-0  border border-sky-100 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_5px_#08f,0_0_2px_#08f]'></div>
//                       </button>
//                     </NavLink>
//                   </div>

//                   )}


//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//         {isLoading1 && (
//           <div className=''>
//             <div className='z-100'>
//               {/* <Loading isVisible1={isLoading1} />*/}
//             </div>
//           </div>
//         )}
//       </nav>
//       <style>
//         {`
//         @media (min-width: 999px) and (max-width: 1033px) {
//           .header_content {
//             display: flex;
//             gap: 40px;
//           }
//         }
//       `}
//       </style>
//     </div>
//   )
// }












import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { faBarsStaggered, faDoorOpen, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import { persistor } from '../../../../../../store/store';
import { logout } from '../../Slice/authSlice';
import "./Header.css";


export default function Header() {
  const [isDropdown, setDropdown] = useState(false);
  const [expandMenu, setExpandMenu] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth?.user?.role);
  const user_email = useSelector((state) => state.auth?.user?.email);
  const verifystep = useSelector((state) => state.auth?.user?.step)

  const isAuthenticated = !!user_email;
  const [openmodal, setOpenModal] = useState(false);
  const toggleDropdown = () => setDropdown(!isDropdown);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);








  const handleLogout = (state) => {
    state.user = null;
    state.accessToken = null;
    persistor.purge();
    dispatch(logout())
    navigate("/")
    toast.success('Logged out successfully');
  };


  const navMenus = {
    default: [
      { label: "Home", path: "/" },
      { label: "Skill Exchange", path: "/seeker/skillarenalist" },
      { label: "Job Arena", path: "/seeker/job-arena" },
      { label: "Courses", path: "/courses" },
      { label: "Know Yourself", path: "/event/start-assessment" },
      { label: "Events", path: "/jordan-event" },
      { label: "Dna", path: "/dna-home" },
    ],
    seeker: [
      { label: "Home", path: "/" },
      { label: "Skill Exchange", path: "/seeker/skillarenalist" },
      { label: "Job Arena", path: "/seeker/job-arena" },
      { label: "Courses", path: "/courses" },
      { label: "Know Yourself", path: "/event/start-assessment" },
      { label: "Events", path: "/jordan-event" },
      { label: "Dna", path: "/dna-home" },
    ],
    recruiter: [
      { label: "Home", path: "/recruiter" },
      { label: "Skill Exchange", path: "/recruiter/skillarenalist" },
      { label: "Job Arena", path: "/recruiter/job-opening-registration" },
      { label: "Know Yourself", path: "/event/start-assessment" },
      { label: "Events", path: "/jordan-event" },
      { label: "Dna", path: "/dna-home" },
    ],
    validator: [
      { label: "Home", path: "/validator" },
      { label: "Manage Q/A", path: "/" },
      { label: "Create Q/A", path: "/" },
      { label: "Wallet", path: "/" },


    ],
  };
  const menuItems = role ? navMenus[role] : navMenus.default;
  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
  };

   const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      openmodal &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
    ) {
        setOpenModal(false);
    }
};

 useEffect(() => {
           document.addEventListener('mousedown', handleClickOutside);
           return () => {
               document.removeEventListener('mousedown', handleClickOutside);
           };
       }, [openmodal]);

  return (
    <div className="">
    <nav className=" z-50 w-full top-0  flex justify-between items-center py-4 px-2 md:px-10  text-white">
      <div className="w-[35%] lg:w-[15%] rounded-full py-2.5 px-3">
        <a href="/">
          <img src="/assets/Logo/d5artWhite.png" className="w-28 mx-auto  " alt="Logo" />
        </a>
      </div>

      <div className="w-[15%] lg:w-[85%] lg:block hidden ">
        <div className=" flex justify-center">
          <div className=" lg:flex justify-center gap-8 backdrop-blur-md  bg-gradient-to-r from-[#00768B]/50 to-[#0E2DA7]/50 rounded-full py-2.5 px-10">
            {menuItems.map((item, index) => (
              <div key={index} className="relative group">
                {item.submenu ? (
                  <>
                    <button
                      onMouseEnter={() => setOpenSubmenu(index)}
                      onMouseLeave={() => setOpenSubmenu(null)}
                      className="hover:text-yellow-400"
                    >
                      {item.label} &#9662;
                    </button>
                    <div
                      className={`absolute left-0 mt-2 w-40 bg-gray-800 text-white rounded shadow-lg transition-all ${openSubmenu === index ? "opacity-100 visible" : "opacity-0 invisible"
                        }`}
                    >
                      {item.submenu.map((subItem, subIndex) => (
                        <a
                          key={subIndex}
                          href={subItem.path}
                          className="block px-4 py-2 hover:bg-gray-700"
                        >
                          {subItem.label}
                        </a>
                      ))}
                    </div>
                  </>
                ) : (
                  <NavLink to={item.path} className="hover:text-blue-400 transition">
                    {item.label}
                  </NavLink>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* {isAuthenticated ? (
        <div className=" relative flex items-center gap-3 ">
          <div className=" flex items-center gap-5 backdrop-blur-md bg-gradient-to-r from-[#00768B]/50 to-[#0E2DA7]/50 rounded-full sm:px-5 px-2 py-1 md:py-2 " >
            <a href={`/${role}/dashboard`} className=" text-sm font-bricolage font-medium ">Dashboard</a>
            <span className="w-8 h-8 rounded-full bg-white/15 flex items-center justify-center">
              {user_email?.charAt(0).toUpperCase()}
            </span>
          </div>
          <div className="w-10 h-10 flex justify-center backdrop-blur-md items-center gap-5 bg-gradient-to-r from-[#00768B]/50 to-[#0E2DA7]/50 rounded-full px-5 py-2 " >
            <button onClick={() => setOpenModal(!openmodal)}>
              <FontAwesomeIcon icon={faBarsStaggered} />
            </button>
          </div>

        </div>
      ) : (
        <NavLink to="/seeker-login">
          <button className="px-4 py-2 rounded-full border border-blue-500 text-white hover:bg-blue-500 transition">
            Login
          </button>
        </NavLink>
      )} */}


{isAuthenticated && (role === "seeker" || role === "recruiter" || role === "validator") && 
  (role !== "seeker" || verifystep === "step5") ? (
    <div className="relative flex items-center gap-3">
      <div className="flex items-center gap-5 backdrop-blur-md bg-gradient-to-r from-[#00768B]/50 to-[#0E2DA7]/50 rounded-full sm:px-5 px-2 py-1 md:py-2">
        <a href={`/${role}/dashboard`} className="text-sm font-bricolage font-medium">Dashboard</a>
        <span className="w-8 h-8 rounded-full bg-white/15 flex items-center justify-center">
          {user_email?.charAt(0).toUpperCase()}
        </span>
      </div>
      <div className="w-10 h-10 flex justify-center backdrop-blur-md items-center gap-5 bg-gradient-to-r from-[#00768B]/50 to-[#0E2DA7]/50 rounded-full px-5 py-2">
        <button  ref={buttonRef} onClick={() => setOpenModal(!openmodal)}>
          <FontAwesomeIcon icon={faBarsStaggered} />
        </button>
      </div>
    </div>
  ) : role === "seeker" && (verifystep === "step1" || verifystep === "step2" || verifystep === "step3" || verifystep === "step4" || verifystep === null || verifystep === "") ? (
    <div className="w-10 h-10 z-50 flex justify-center backdrop-blur-md items-center gap-5 bg-gradient-to-r from-[#00768B]/50 to-[#0E2DA7]/50 rounded-full px-5 py-2">
      <button  ref={buttonRef} onClick={() => setOpenModal(!openmodal)}>
        <FontAwesomeIcon icon={faBarsStaggered} />
      </button>
    </div>
  ) : (
    <div>
      <div className=" md:hidden block ">
    <div className="w-10 h-10  flex justify-center backdrop-blur-md items-center gap-5 bg-gradient-to-r from-[#00768B]/50 to-[#0E2DA7]/50 rounded-full px-5 py-2">
    <button  ref={buttonRef} onClick={() => setOpenModal(!openmodal)}>
      <FontAwesomeIcon icon={faBarsStaggered} />
    </button>
  </div>
  </div>
    <NavLink to="/seeker-login" className=" md:block hidden"> 
   
     <button className="animated-button md:block hidden">
<svg xmlns="http://www.w3.org/2000/svg" className="arr-2" viewBox="0 0 24 24">
  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z" />
</svg>
<span className="text">Login</span>
<span className="circle"></span>
<svg xmlns="http://www.w3.org/2000/svg" className="arr-1" viewBox="0 0 24 24">
  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z" />
</svg>
</button>
  </NavLink> 
  </div>
)}

      {openmodal && (
        <div  ref={dropdownRef} className=" absolute top-20 right-5 z-50  bg-black px-5 py-2 rounded-lg ">
          {isAuthenticated ? (
            <div className="relative ">
              <button onClick={toggleDropdown} className="w-full flex justify-start  items-center gap-3 ">
                <span className="w-8 h-8 rounded-full bg-white/15 flex items-center justify-center">
                  {user_email.charAt(0).toUpperCase()}
                </span>
                <span>{user_email.split("@")[0]}</span>
              </button>
              <div className=" lg:hidden block text-center ">
                {menuItems.map((item, index) => (
                  <NavLink key={index} to={item.path} className="block py-2 hover:text-yellow-400">
                    {item.label}
                  </NavLink>
                ))}
              </div>
              {/* {isDropdown && ( */}
              {role === "seeker" && (verifystep === 'step1' || verifystep === 'step2' || verifystep === 'step3' || verifystep === 'step4' || verifystep === null || verifystep === '') && (

                <a href="/seeker/profile-update"> <div className=" mt-2  flex items-center gap-2 text-white">
                  <span className="w-8 h-8  rounded-full flex items-center justify-center"><FontAwesomeIcon icon={faUser} /></span>
                  <button onClick="" className="block w-full px-1 py-2 text-left hover:bg-gray-700">
                    Update Profile
                  </button>

                </div>  </a>
              )}

              <div className=" mt-2  flex items-center gap-2 text-white">
                <span className="w-8 h-8  rounded-full flex items-center justify-center"><FontAwesomeIcon icon={faDoorOpen} /></span>

                <button onClick={handleLogout} className="block w-full px-1 py-2 text-left hover:bg-gray-700">
                  Logout
                </button>
              </div>
              {/* )} */}
            </div>
          ) : (
            <div className=" md:hidden block">
              <div className=" md:hidden block text-center ">
                {menuItems.map((item, index) => (
                  <NavLink key={index} to={item.path} className="block py-2 hover:text-yellow-400">
                    {item.label}
                  </NavLink>
                ))}
               
              </div>
              <NavLink to="/seeker-login" className=""> 
   
      <button className="animated-button">
<svg xmlns="http://www.w3.org/2000/svg" className="arr-2" viewBox="0 0 24 24">
  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z" />
</svg>
<span className="text">Login</span>
<span className="circle"></span>
<svg xmlns="http://www.w3.org/2000/svg" className="arr-1" viewBox="0 0 24 24">
  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z" />
</svg>
</button>
  </NavLink> 
            </div>
          )}

        </div>
      )}


      {isDropdownOpen && (
        <div className="absolute top-16 right-5 z-50 w-48 bg-black text-white rounded-lg p-4 space-y-2 shadow-lg">
          {menuItems.map((item, index) => (
            <div key={index}>
              {item.submenu ? (
                <>
                  <button onClick={() => handleSubmenuToggle(index)} className="w-full text-left cursor-pointer">
                    {item.label} &#9662;
                  </button>
                  {openSubmenu === index && (
                    <div className="pl-4">
                      {item.submenu.map((subItem, subIndex) => (
                        <NavLink key={subIndex} to={subItem.path} className="block py-1">
                          {subItem.label}
                        </NavLink>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <NavLink to={item.path} className="block">
                  {item.label}
                </NavLink>
              )}
            </div>
          ))}

          {isAuthenticated ? (
            <button onClick={handleLogout} className="block w-full text-left mt-2">
              Logout
            </button>
          ) : (
            <NavLink to="/seeker-login" className="block text-center ">
             <button className="animated-button">
<svg xmlns="http://www.w3.org/2000/svg" className="arr-2" viewBox="0 0 24 24">
  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z" />
</svg>
<span className="text">Login</span>
<span className="circle"></span>
<svg xmlns="http://www.w3.org/2000/svg" className="arr-1" viewBox="0 0 24 24">
  <path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z" />
</svg>
</button>
            </NavLink>
          )}
        </div>
      )}


      {/* Mobile Menu */}
      {/* <div className="lg:hidden">
        <button onClick={toggleDropdown} ref={buttonRef}>
          <i className="fa-solid fa-bars text-2xl"></i>
        </button>
        {isDropdown && (
          <div ref={dropdownRef} className="absolute right-5 top-16 w-48 bg-gray-900 text-white rounded-lg p-4">
            {menuItems.map((item, index) => (
              <NavLink key={index} to={item.path} className="block py-2 hover:text-yellow-400">
                {item.label}
              </NavLink>
            ))}
          </div>
        )}
      </div> */}
    </nav>
    </div>
  );
}












